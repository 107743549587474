import {createRouter, createWebHistory} from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from '../store'
import CalendarComponent from "@/components/CalendarComponent.vue";

let user = store.getters['getUser']
let token = store.getters['getToken']

function guardMyAdminRoute(to, from, next) {

    let isAdminAuthenticated = false;

    isAdminAuthenticated = !!(user.role === 1 && token[0]);
    console.log(isAdminAuthenticated)
    if (isAdminAuthenticated) {
        next();
    } else {
        next('/signin')
    }
}

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
            title: 'Skylrs Kitchen - Welcome',
            metaTags: [
                {
                    name: 'description',
                    content: 'Welcome to Skylrs Kitchen, the app that brings you delicious recipes from Chef Skyler, a young English chef who loves to cook with passion, flair, and creativity. Inspired by the likes of Nigella Lawson, Chef Skyler believes that cooking should be fun, easy, and satisfying. '
                }
            ]
        }
    },
    {
        path: "/calendar",
        name: "calendar",
        component: CalendarComponent,
    },
    {
        path: "/about",
        name: "about",
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
        meta: {
            title: 'Skylrs Kitchen - About Skylr',
            metaTags: [
                {
                    name: 'description',
                    content: 'Do you love delicious and healthy food, but don’t have the time or inspiration to cook? Then you need Skylr’s Kitchen, the AI recipe chef that creates amazing dishes for you based on your preferences and ingredients'
                }
            ]
        }
    },
    {
        path: "/signin",
        name: "signin",
        component: () =>
            import("../auth/LoginView.vue"),
    },
    {
        path: "/signup",
        name: "signup",
        component: () =>
            import("../auth/CreateAccountView.vue"),
    },
    {
        path: "/fodmap",
        name: "fodmap",
        component: () =>
            import("../views/FodmapView.vue"),
        meta: {
            title: 'Skylrs Kitchen - What is Low FodMap',
            metaTags: [
                {
                    name: 'description',
                    content: 'One possible solution is to try a low FODMAP diet, which is a scientifically proven dietary approach that can help manage IBS symptoms in up to 75% of people1. But what exactly is low FODMAP and how does it work?'
                }
            ]
        }
    },
    {
        path: "/gluten",
        name: "gluten",
        component: () =>
            import("../views/GlutenView.vue"),
        meta: {
            title: 'Skylrs Kitchen - What is Gluten Free',
            metaTags: [
                {
                    name: 'description',
                    content: 'If you have been diagnosed with celiac disease, a wheat allergy, or a gluten sensitivity, you may need to follow a gluten and wheat free diet. '
                }
            ]
        }
    },
    {
        path: "/dairy",
        name: "dairy",
        component: () =>
            import("../views/DairyFreeView.vue"),
        meta: {
            title: 'Skylrs Kitchen - What is Gluten Free',
            metaTags: [
                {
                    name: 'description',
                    content: 'Dairy free diets are becoming more popular for various reasons, such as health, ethics, or environmental concerns. But what does it mean to go dairy free, and how can you do it without compromising your nutrition or enjoyment of food? '
                }
            ]
        }
    },
    {
        path: "/support",
        name: "support",
        component: () =>
            import("../views/SupportView.vue"),
        meta: {
            title: 'Skylrs Kitchen - Support Us',
            metaTags: [
                {
                    name: 'description',
                    content: 'Cook up some kindness with your donation today. Your generosity helps us share delicious recipes with the world.'
                }
            ]
        }
    },
    {
        path: "/recipe",
        name: "recipe",
        props: true,
        component: () =>
            import("../views/RecipePage.vue"),
        meta: {
            title: 'Skylrs Kitchen - Your recipe',
            metaTags: [
                {
                    name: 'description',
                    content: 'This is he AI generated recipe'
                }
            ]
        }
    },
    {
        path: "/profile",
        name: "profile",
        props: true,
        component: () =>
            import("../views/ProfilePageView.vue"),
    },
    {
        path: "/fodmap-checker",
        name: "checker",
        props: true,
        component: () =>
            import("../views/FodmapChecker.vue"),
        meta: {
            title: 'Skylrs Kitchen - FodMap Checker',
            metaTags: [
                {
                    name: 'description',
                    content: 'This low FODMAP ingredient checker is a tool that can help you identify and avoid foods that contain high levels of FODMAPs'
                }
            ]
        }
    },
    {
        path: "/admin/create-blog",
        name: "create_blog",
        props: true,
        beforeEnter: guardMyAdminRoute,
        component: () =>
            import("../admin/CreateBlog.vue"),
        meta: {
            title: 'Skylrs Kitchen - Create Blog',
            metaTags: [
                {
                    name: 'description',
                    content: 'Create a new article for skylrs kitchen'
                }
            ]
        }
    },
    {
        path: "/articles",
        name: "all_blog",
        props: true,
        component: () =>
            import("../views/BlogsView.vue"),
        meta: {
            title: 'Skylrs Kitchen - Articles',
            metaTags: [
                {
                    name: 'description',
                    content: 'Articles and recipes from Skylrs Kitchen'
                }
            ]
        }
    },
    {
        path: "/category/:category",
        name: "blogs_by_category",
        props: true,
        component: () =>
            import("../views/PostsByCategoryView.vue"),
        meta: {
            title: 'Skylrs Kitchen - Recipes',
            metaTags: [
                {
                    name: 'description',
                    content: 'Articles and recipes from Skylrs Kitchen'
                }
            ]
        }
    },
    {
        path: "/article/:slug",
        name: "article",
        props: true,
        component: () =>
            import("../views/BlogView.vue"),
        meta: {
            title: 'Skylrs Kitchen - Article',
            metaTags: [
                {
                    name: 'description',
                    content: 'Articles and recipes from Skylrs Kitchen'
                }
            ]
        }
    },
    {
        path: "/twitter-fetch",
        name: "twitter",
        props: true,
        beforeEnter: guardMyAdminRoute,
        component: () =>
            import("../admin/TwitterCreate.vue"),
        meta: {
            title: 'Skylrs Kitchen - Twitter Create',
            metaTags: [
                {
                    name: 'description',
                    content: 'Create a tweet '
                }
            ]
        }
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const nearestWithTitle        = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const nearestWithMeta         = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    if(nearestWithTitle) {
      document.title = nearestWithTitle.meta.title;
    } else if(previousNearestWithMeta) {
      document.title = previousNearestWithMeta.meta.title;
    }

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    if(!nearestWithMeta) return next();

    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    .forEach(tag => document.head.appendChild(tag));

    next();
  });


export default router;
