<template>
  <div class="bg-skylrsWhite noPrint">
    <div class="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
      <div class="relative isolate overflow-hidden bg-skylrsWhite px-6 pt-16 sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
        <div class="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
          <h2 class="text-3xl font-bold tracking-tight text-skylrsBrown sm:text-4xl">Skylr’s Kitchen is more<br />than just an app.</h2>
          <p class="mt-6 text-lg leading-8 text-skylrsDark">If you love the food and enjoy the recipe, please consider showing your support by making a donation. Your generosity helps us keep Skylr’s Kitchen running and improving.</p>
          <div class="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
            <a href="https://www.buymeacoffee.com/skylrskitchen" target="_blank" class="bg-skylrsBrown text-white font-bold py-3 px-3 text-sm rounded-lg shadow-md hover:bg-skylrsDark inline-block mx-auto">Donate</a>
          </div>
        </div>
        <div class="relative mt-16 h-80 lg:mt-8">
          <img class="absolute left-0 top-0 w-[33rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10" src="../assets/support1.jpeg" alt="Support Skylrs Kitchen" width="1824" height="1080" />
        </div>
      </div>
    </div>
  </div>
</template>