<template>
   <!-- Main Container -->
  <div class="container mx-auto px-4 py-8">

    <!-- Blog Section with Sidebar -->
    <div class="flex flex-col lg:flex-row w-full">

      <!-- Blog Posts (3/4 width) -->
      <div class="lg:w-3/4 lg:pr-8">
        <!-- Section Title -->
        <h2 class="text-3xl font-bold text-skylrsBrown mb-8">Latest Posts &
          <span class="text-skylrsYellow">Scrumptious</span> Recipes</h2>

        <!-- Blog List -->
        <div class="space-y-8">
          <!-- Blog Post 1 -->
          <div v-for="post in posts" :key="post.id" class="flex flex-col lg:flex-row items-start lg:items-center w-full">
            <img :src="this.url + post.image" alt="Blog 1" class="h-48 w-full lg:w-1/3 object-cover mb-4 lg:mb-0 lg:mr-6">
            <div class="flex flex-col">
              <h3 class="text-2xl font-semibold text-skylrsBrown mb-2">{{ post.title }}</h3>
              <p class="mt-5 text-sm leading-6 text-gray-600 line-clamp-3">{{ stripTags(post.body) }}</p>
              <p class="text-gray-500 text-sm mb-4">{{ $moment(post.created_at).format("MMMM Do YYYY, h:mm:ss a") }}</p>
              <router-link :to="'/article/' + post.slug" class="mt-4 bg-skylrsRed text-white font-bold py-2 px-4 rounded hover:bg-skylrsYellow w-32">Read More</router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- Sidebar (CTA Section - 1/4 width) -->
      <div class="lg:w-1/4 mt-8 lg:mt-0">
        <!-- CTA Section Title -->
        <h2 class="text-2xl font-bold text-skylrsBrown mb-4">Our Newsletter</h2>

        <!-- CTA Content -->
        <div class="p-6 rounded-lg">
          <h2 class="text-1xl font-bold mb-4 text-skylrsBrown">Unlock Delicious Inspiration!</h2>
          <p class="text-sm mb-4">Get the freshest recipes, exclusive tips, and mouthwatering ideas delivered right to your inbox. Don't miss out—join our recipe revolution today and spice up your cooking adventures! 🌟🍽️</p>
          <a href="link-to-signup" class="mt-4 bg-skylrsRed text-white font-bold py-2 px-4 rounded hover:bg-skylrsYellow">Sign Up Now</a>
        </div>

        <!-- Additional CTA Block -->
        <div class="rounded-lg mt-8 bg-skylrsRed">
          <a href="/fodmap" class="block w-full">
            <img src="../assets/whatsalowfod.jpg" alt="Whats a low fod map diet?" class="w-full h-auto object-cover rounded-lg">
          </a>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      posts: [],
      api: process.env.VUE_APP_API_ENDPOINT,
      url: ""
    }
  },
  methods: {
    getArticles() {
      this.axios.get(this.api + '/articles/get_three').then(response => {
        this.posts = response.data.posts
        this.url = response.data.url
      })
    },
    stripTags(original) {
      return original.replace(/(<([^>]+)>)/gi, "");
    },
  },
  created() {
    this.getArticles()
  }
}

</script>