<template>
  <hero-section></hero-section>
  <BannerIos></BannerIos>
  <BlogsThree></BlogsThree>
  <div class="overflow-hidden bg-skylrsWhite py-32">
    <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
      <div
        class="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8"
      >
        <div class="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
          <h2
            class="text-3xl font-bold tracking-tight text-skylrsBrown sm:text-4xl"
            id="letscreate"
          >
            Lets Create a Recipe
          </h2>
          <p class="mt-6 text-xl leading-8 text-skylrsDark">
            No matter your dietary needs, Chef Skylr can cater to them. Simply
            tell her your cooking preferences and any other special requests,
            and she will provide you with a mouth-watering recipe for your ideal
            dinner.
          </p>
          <div class="mt-6">
            <div class="grid grid-cols-1 sm:grid-cols-4 gap-x-4">
              <div class="col-span-full sm:col-span-3">
                <label for="content" class="mt-10 text-sm text-skylrsDark"
                  >What kind of cuisine or dish are you craving?</label
                >
                <div class="mt-2">
                  <input
                    type="text"
                    v-model="recipe"
                    name="content"
                    id="content"
                    class="pl-2 block w-full rounded-md border-0 py-1.5 text-skylrsDark shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="I would like to make a veggie pie..."
                  />
                </div>
              </div>
              <div class="mt-3 sm:mt-0 col-span-full sm:col-span-1">
                <label for="people" class="mt-10 text-md text-skylrsDark text-sm"
                  >People To Serve</label
                >
                <div class="mt-2">
                  <input
                    type="number"
                    v-model="people"
                    name="people"
                    id="people"
                    class="pl-2 block w-full rounded-md border-0 py-1.5 text-skylrsDark shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="2"
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>

            <fieldset class="mt-6 flex justify-between">
              <legend class="sr-only">Options</legend>
              <div class="space-y-5">
                <div class="relative flex items-start">
                  <div class="flex h-6 items-center">
                    <input
                      id="fodmap"
                      v-model="fodmap"
                      name="fodmap"
                      type="checkbox"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div class="ml-3 text-sm leading-6">
                    <label for="fodmap" class="font-medium text-skylrsDark"
                      >Low FODMAP</label
                    >
                  </div>
                </div>
                <div class="relative flex items-start">
                  <div class="flex h-6 items-center">
                    <input
                      id="gluten"
                      v-model="gluten"
                      name="gluten"
                      type="checkbox"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div class="ml-3 text-sm leading-6">
                    <label for="gluten" class="font-medium text-skylrsDark"
                      >Gluten and wheat free</label
                    >
                  </div>
                </div>
                <div class="relative flex items-start">
                  <div class="flex h-6 items-center">
                    <input
                      id="dairy"
                      v-model="dairy"
                      name="dairy"
                      type="checkbox"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div class="ml-3 text-sm leading-6">
                    <label for="dairy" class="font-medium text-skylrsDark"
                      >Dairy Free</label
                    >
                  </div>
                </div>
              </div>
              <!-- NEW CHECKBOXES HERE replace option v-models -->
              <div class="space-y-5">
                <div class="relative flex items-start">
                  <div class="flex h-6 items-center">
                    <input
                      id="vegetarian"
                      v-model="option4"
                      name="vegetarian"
                      type="checkbox"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div class="ml-3 text-sm leading-6">
                    <label for="vegetarian" class="font-medium text-skylrsDark"
                      >Vegetarian</label
                    >
                  </div>
                </div>
                <div class="relative flex items-start">
                  <div class="flex h-6 items-center">
                    <input
                      id="vegan"
                      v-model="option5"
                      name="vegan"
                      type="checkbox"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div class="ml-3 text-sm leading-6">
                    <label for="vegan" class="font-medium text-skylrsDark"
                      >Vegan</label
                    >
                  </div>
                </div>
                <div class="relative flex items-start">
                  <div class="flex h-6 items-center">
                    <input
                      id="calorie"
                      v-model="option6"
                      name="calorie"
                      type="checkbox"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div class="ml-3 text-sm leading-6">
                    <label for="calorie" class="font-medium text-skylrsDark"
                      >Low Calorie</label
                    >
                  </div>
                </div>
              </div>
            </fieldset>
            <button
              v-if="loading === false"
              class="mt-4 bg-skylrsRed text-white font-bold py-2 px-4 rounded hover:bg-skylrsYellow"
              @click="this.getRecipe"
            >
              Get me a recipe
            </button>
            <button
              v-else
              type="button"
              class="mt-6 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-skylrsRed hover:bg-red-600 transition ease-in-out duration-150 cursor-not-allowed"
              disabled=""
            >
              <svg
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Cooking...
            </button>
          </div>
        </div>
        <div
          class="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents"
        >
          <div
            class="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end"
          >
            <img
              src="../assets/food/food%202.jpeg"
              alt=""
              class="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
            />
          </div>
          <div
            class="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8"
          >
            <div
              class="order-first flex w-64 flex-none justify-end self-end lg:w-auto"
            >
              <img
                src="../assets/food/roast.jpeg"
                alt=""
                class="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
            <div class="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
              <img
                src="../assets/food/salmon.jpeg"
                alt=""
                class="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
            <div
              class="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none"
            >
              <img
                src="../assets/food/cake.jpeg"
                alt=""
                class="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="relative isolate overflow-hidden bg-zinc-950 max-w-[85rem] mx-auto"
  >
    <DonationView></DonationView>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import HeroSection from "@/components/HeroSection.vue";
import DonationView from "@/components/DonationView.vue";
import BlogsThree from "@/components/BlogsThree.vue";
import BannerIos from "@/components/BannerIos.vue";
export default {
  name: "HomeView",
  components: {
    HeroSection,
    DonationView,
    BlogsThree,
    BannerIos
  },
  data() {
    return {
      recipe: "",
      people: "",
      fodmap: false,
      gluten: false,
      dairy: false,
      option4: false,
      option5: false,
      option6: false,
      callback: "",
      loading: false,
      recipe_loaded: false,
      api: process.env.VUE_APP_API_ENDPOINT,
    };
  },
  methods: {
    ...mapMutations(["RECIPE"]),
    formatText(text) {
      // Split the text by line breaks
      let lines = text.split("\n");
      // Initialize an empty array to store the formatted lines
      let formattedLines = [];
      // Loop through each line
      for (let line of lines) {
        // Trim any leading or trailing whitespace
        line = line.trim();
        // If the line starts with a dash, wrap it in a list item tag
        if (line.startsWith("-")) {
          line = `<li>${line.slice(1).trim()}</li>`;
        }
        // If the line is a number followed by a dot, wrap it in an ordered list item tag
        else if (/^\d+\./.test(line)) {
          line = `<li>${line.slice(line.indexOf(".") + 1).trim()}</li>`;
        }
        // If the line is not empty, wrap it in a paragraph tag
        else if (line) {
          line = `<p class="font-bold mb-3 mt-3">${line}</p>`;
        }
        // Push the formatted line to the array
        formattedLines.push(line);
      }
      // Join the formatted lines with line breaks and return the result
      return formattedLines.join("\n");
    },
    getRecipe() {
      let data = {
        content: this.recipe,
        fodmap: this.fodmap,
        dairy: this.dairy,
        vegan: this.option5,
        veggie: this.option4,
        calorie: this.option6,
        gluten: this.gluten,
        people: this.people
      };
      this.loading = true;
      this.axios.post(this.api + "/recipes/ask", data).then((response) => {
        //this.callback = this.formatText(response.data.data);
        this.RECIPE(response.data.data);
        window.location.href = "recipe";
      });
    },
  },
};
</script>
