<template>
  <!-- Banner Component -->
  <div class="bg-skylrsRed text-white py-8 px-4 lg:px-8 mt-20 mb-20">
    <div class="container mx-auto flex flex-col lg:flex-row items-center justify-between">

      <!-- Text Content -->
      <div class="flex flex-col lg:w-3/4 lg:mr-8 mb-6 lg:mb-0">
        <h2 class="text-3xl lg:text-4xl font-bold mb-4 text-skylrsWhite">Create Your Own Recipes Anywhere!</h2>
        <p class="text-lg mb-4 text-skylrsWhite">Download our iOS app and start crafting your favorite recipes right from your phone. It's easy, fun, and perfect for culinary enthusiasts!</p>
        <a href="https://apps.apple.com/app/skylrs-kitchen/id6670477068" class="bg-skylrsBrown text-white font-bold py-3 px-3 text-sm rounded-lg shadow-md hover:bg-skylrsDark inline-block mx-auto">Get the app now</a>      </div>

      <!-- App Image -->
      <div class="lg:w-1/4 flex justify-center lg:justify-end">
        <img src="../assets/skylr.png" alt="iOS App" class="w-48 h-auto rounded-md">
      </div>

    </div>
  </div>
</template>