import { createStore } from 'vuex'
import VuexPersist from 'vuex-persist';

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
})

export default createStore({
  state: {
    loggedUser: false,
    user: [],
    token: [],
    reset_token: [],
    recipe: []
  },
  getters: {
    loggedUser: (state) => {
      return state.loggedUser
    },
    getUser: (state) => {
      return state.user
    },
    getRecipe: (state) => {
      return state.recipe
    },
    getToken: (state) => {
      return state.token
    },
    getRefresh: (state) => {
      return state.reset_token
    }
  },
  mutations: {
    ADD_USER_DATA(state, data) {
      state.user = data
    },
    LOGGED_IN(state) {
      state.loggedUser = true
    },
    LOGGED_OUT(state) {
      state.loggedUse = false
    },
    UPLOAD_TOKEN(state, data) {
      state.token.push(data)
    },
    UPLOAD_RESET(state, data) {
      state.reset_token = ''
      state.reset_token = data
    },
    SIGN_OUT(state) {
      state.user = []
      state.loggedUser = false
      state.token = []
    },
    RECIPE(state, data) {
      state.recipe = data
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocalStorage.plugin]
})
