<template>
  <div
      class="relative isolate overflow-hidden bg-zinc-950 max-w-[85rem] mx-auto"
  >
    <div class="lg:px-20 lg:py-5 p-5" id="recipe">
      <p class="flex justify-center">
        <img src="../assets/comingsoon.png" class="w-72 noPrint" alt="recipe"/>
      </p>
    </div>
  </div>
</template>
<script setup>
</script>

<!--
<template>
  <div class="max-w-7xl mx-auto p-10">
    <header
        class="rounded-tl-lg rounded-tr-lg flex items-center bg-zinc-800 justify-between border-b border-zinc-600 px-6 py-4 lg:flex-none">
      <h1 class="text-base font-semibold leading-6 text-white">
        <time>{{ this.month }}</time>
      </h1>
    </header>
    <div class=" rounded-bl-lg rounded-br-lg shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col overflow-hidden">
      <div
          class="grid grid-cols-7 gap-px border-b border-zinc-700 bg-zinc-700 text-center text-xs font-semibold leading-6 text-zinc-300 lg:flex-none">
        <div class="bg-zinc-800 py-2">S<span class="sr-only sm:not-sr-only">un</span></div>
        <div class="bg-zinc-800 py-2">M<span class="sr-only sm:not-sr-only">on</span></div>
        <div class="bg-zinc-800 py-2">T<span class="sr-only sm:not-sr-only">ue</span></div>
        <div class="bg-zinc-800 py-2">W<span class="sr-only sm:not-sr-only">ed</span></div>
        <div class="bg-zinc-800 py-2">T<span class="sr-only sm:not-sr-only">hu</span></div>
        <div class="bg-zinc-800 py-2">F<span class="sr-only sm:not-sr-only">ri</span></div>
        <div class="bg-zinc-800 py-2">S<span class="sr-only sm:not-sr-only">at</span></div>
      </div>
      <div class="rounded-bl-lg rounded-br-lg flex bg-zinc-700 text-xs leading-6 text-zinc-300 lg:flex-auto">
        <div class="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
          <div v-for="(day, index) in days" :key="day.date"
               :class="[day.isCurrentMonth ? 'bg-zinc-800' : 'bg-zinc-900',
                day.isNextMonth ? 'bg-gray-500' : '',
                'relative px-3 py-2' ]">
            <time :datetime="day.date"
                  :class="day.isToday ? 'flex h-6 w-6 items-center justify-center rounded-full bg-skylrsRed font-semibold text-white' : undefined">
              {{ day.date.split('-').pop().replace(/^0/, '') }}
            </time>
            <ol v-if="day.events.length > 0" class="mt-2">
              <li v-for="event in day.events.slice(0, 3)" :key="event.id">
                <a :href="event.href" class="group flex">
                  <a :href="event.href" class="flex-auto truncate font-medium text-white group-hover:text-indigo-600">
                    {{ event.name }}
                  </a>
                  <time :datetime="event.datetime"
                        class="ml-3 hidden flex-none text-zinc-400 group-hover:text-indigo-600 xl:block">{{
                      event.time
                    }}
                  </time>
                </a>
              </li>
              <li v-if="day.events.length > 3" class="text-zinc-400">+ {{ day.events.length - 3 }} more</li>
            </ol>
          </div>
        </div>
        <div class="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
          <button v-for="day in days" :key="day.date" type="button"
                  @click="setSelectDay(day)"
                  :class="[day.isCurrentMonth ? 'bg-zinc-800' : 'bg-zinc-900', (day.isSelected || day.isToday) && 'font-semibold', day.isSelected && 'text-white', !day.isSelected && day.isToday && 'text-indigo-600', !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-white', !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-zinc-400', 'flex h-14 flex-col px-3 py-2 hover:bg-zinc-900 focus:z-10']">
            <time :datetime="day.date"
                  :class="[day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full', day.isSelected && day.isToday && 'bg-indigo-600', day.isSelected && !day.isToday && 'bg-gray-900', 'ml-auto']">
              {{ day.date.split('-').pop().replace(/^0/, '') }}
            </time>
            <span class="sr-only">{{ day.events.length }} events</span>
            <span v-if="day.events.length > 0" class="-mx-0.5 mt-auto flex flex-wrap-reverse">
              <span v-for="event in day.events" :key="event.id"
                    class="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-zinc-700"/>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div v-if="selectedDay?.events.length > 0" class=" py-10 ">
      <ol class="divide-y divide-zinc-700 overflow-hidden rounded-lg bg-zinc-800 text-sm shadow ring-1 ring-black ring-opacity-5">
        <li v-for="event in selectedDay.events" :key="event.id"
            class="group flex p-4 pr-6 focus-within:bg-zinc-900">
          <div class="flex-auto">
            <p class=" font-semibold  text-white"><span class="text-gray-400 font-normal">{{event.mealtime + ':  '}}</span>{{ event.name }}</p>
            <a class="text-indigo-600 hover:text-indigo-500" :href="event.href">View Recipe</a>
&lt;!&ndash;            <time :datetime="event.datetime" class="mt-2 flex items-center text-zinc-300">&ndash;&gt;
&lt;!&ndash;              {{ event.time }}&ndash;&gt;
&lt;!&ndash;            </time>&ndash;&gt;
          </div>
        </li>
      </ol>
    </div>
  </div>

</template>
<script>
import {ClockIcon,} from '@heroicons/vue/20/solid'
import moment from "moment";

export default {
  components: {
    ClockIcon,
  },
  data() {
    return {
      month: "",
      days: [],
      selectedDay: null,
      mealPlans: [],
    }
  },
  methods: {
    getMonth() {
      const today = moment(new Date());
      this.month = today.format('MMMM YYYY');
    },
    setSelectDay(day) {
      for (let i = 0; i < this.days.length; i++) {
        this.days[i].isSelected = false;
      }
      day.isSelected = true;
      this.selectDay();
    },
    selectDay() {
      this.selectedDay = this.days.find((day) => day.isSelected);
    },
    getDays() {
      const today = moment();
      const startDay = today.clone().startOf('month').startOf('week');
      const endDay = startDay.clone().add(5, 'weeks').endOf('week');
      let date = startDay.clone().subtract(1, 'day');

      while (date.isBefore(endDay, 'day')) {
        this.days.push({
          date: date.add(1, 'day').clone().format('YYYY-MM-DD'),
          isCurrentMonth: moment(date).isSame(new Date(), 'month'),
          isToday: moment(date).isSame(new Date(), 'day'),
          isNextMonth: moment(date).isAfter(moment().endOf('month')),
          events: []
        });
      }
      this.setToday();
    },
    setToday() {
      for (let i = 0; i < this.days.length; i++) {
        if (moment(this.days[i].date).isSame(new Date(), 'day')) {
          this.days[i].isToday = true
          this.days[i].isSelected = true
        }
      }
      this.selectDay()
    },
    getMealPlans() {
      // Mock data
      this.mealPlans = [
        {
          day: 25,
          month: 4,
          year: 2024,
          name: 'Pancakes',
          days: 1,
          mealtime: 'Breakfast',
          href: '#pancakes'
        },
        {
          day: 25,
          month: 4,
          year: 2024,
          name: 'Ham Sandwich',
          days: 1,
          mealtime: 'Lunch',
          href: '#sandwich'
        },
        {
          day: 25,
          month: 4,
          year: 2024,
          name: 'Pasta',
          days: 1,
          mealtime: 'Dinner',
          href: '#pasta'
        },
      ];
      this.addMealPlans();
    },
    addMealPlans() {
      for (let i = 0; i < this.mealPlans.length; i++) {
        for (let j = 0; j < this.days.length; j++) {
          if (moment(this.days[j].date).date() === this.mealPlans[i].day &&
              moment(this.days[j].date).month() + 1 === this.mealPlans[i].month &&
              moment(this.days[j].date).year() === this.mealPlans[i].year) {
            for (let k = 0; k < this.mealPlans[i].days; k++) {
              this.days[j + k].events.push(
                  {
                    name: `${this.mealPlans[i].name}`,
                    time: `${this.mealPlans[i].days} days`,
                    mealtime: this.mealPlans[i].mealtime,
                    href: this.mealPlans[i].href,
                    datetime: this.days[j].date
                  }
              )
            }
          }
        }
      }
    }
  },
  created() {
    this.moment = moment
  },
  mounted() {
    this.selectDay()
    this.getMonth()
    this.getDays()
    this.getMealPlans()
  }
}


</script>-->
