<template>
  <div class="flex flex-col h-screen">
    <NavBar></NavBar>
    <div class="mb-auto">
    <router-view/>
    </div>
    <FooterNav></FooterNav>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import FooterNav from '../src/components/FooterNav.vue'
import NavBar from "@/components/NavBar.vue";
export default {
  components:{
    FooterNav,
    NavBar
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'loggedUser'
      // ...
    ])
  }
}

</script>
